import React, {useEffect, useState, useRef} from 'react';
import styled from '@emotion/styled';
import { StyledDivButton } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentOptionSelector from './Component-OptionSelector';
import {useTranslation} from 'react-i18next';
import SendIcon from "../../../assets/SendIcon.svg"
import SendIconGrey from "../../../assets/SendIconGrey.svg"
import SendIconDisabled from "../../../assets/SendIconDisabled.svg"

export default function SendMessageComponent(props){

    const {t} = useTranslation();

    // message written
    const [answer, setAnswer] = useState('');
    const [enable, setEnable] = useState(false);

    // reference to the text area
    const textAreaRef = useRef();

    // selects the text area so that the user is available to write
    useEffect(() => {
        if(textAreaRef.current)
            textAreaRef.current.select();
    }, [props.botWriting]);

    // handles if the answer is unselected
    const handleAnswer = (new_answer) => {
        if(new_answer !== "" && new_answer !== answer){
            setAnswer(new_answer)
            setEnable(true)
        }
        else{
            setAnswer("")
            setEnable(false)
        }
      };

    // when the send button is clicked, calls the next message function from PageRootcauseChatbot.js
    function send() {
        if(answer !== ""){
            props.nextMessage(answer);
        }
        setAnswer("");
        setEnable(false)
    }

    return(
        <Container>

            {props.stage.includes("PRESENTATION") || props.stage.includes("INTRODUCTION") ? (
                <ButtonDiv>
                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width="100%" Height='80px' BackgroundColor={!props.botThinking && !props.botWriting ? "#E2336B" : '#E3E5E4'}
                                    FontSize='18px' Display='flex' JustifyContent='center' Position="relative" Cursor={!props.botThinking && !props.botWriting ? "pointer" : 'auto'}
                                    Border={'0px solid #271B36'} Color={'#FCFCFC'}>
                        <button disabled={props.botThinking || props.botWriting} onClick={() => props.nextMessage(props.options[0])}> 
                            {props.options[0]}
                        </button>
                    </StyledDivButton>
                </ButtonDiv>

            ) : !props.botThinking && !props.botWriting ? (
                <>
                    {props.options.length > 0 ? (
                        <ComponentOptionSelector stage={props.stage} options={props.options} handleAnswer={handleAnswer} setEnable={setEnable}/>
                    ) : (
                        <textarea ref={textAreaRef} style={{fontSize: '18px'}} maxLength={500} id="chatbotmessagebox" placeholder={t("PageRootcauseChatbot.MessageBoxPlaceholder")} value={answer} onChange={(e) => handleAnswer(e.target.value)}/>
                    )}
                </>
            ) : (
                <textarea ref={textAreaRef} maxLength={500} id="chatbotmessagebox" placeholder={t("PageRootcauseChatbot.MessageBoxPlaceholder")} value={answer}/>
            )}

            {/* Send button (in the presentation stage there is no send button) */}
            {(!props.stage.includes("PRESENTATION") && !props.stage.includes("INTRODUCTION")) && 
                <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='100px' Height='80px' BackgroundColor={ "#E2336B"}
                                FontSize='18px' Display='flex' JustifyContent='center' Position="relative" Cursor={!props.botThinking && !props.botWriting && enable ? "pointer" : 'auto'}
                                Border={'0px solid #271B36'} Color={enable ? '#FCFCFC' : '#EE85A6'}>
                    <button onClick={() => send()}> 
                        {t("PageRootcauseChatbot.SendButtonText")}
                        <img src={!props.botThinking && !props.botWriting && enable ? SendIcon : !enable ? SendIconDisabled : SendIconGrey}/>
                    </button>
                </StyledDivButton>
            }
            
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: calc(100%);
    height: 80px;
    gap: 16px;

    textarea {
    height: calc(80px - 16px);
    border: none; 
    background-color: #F1F3F4; 
    font-size: 16px; 
    font-family: 'Overpass';
    color: #000000; 
    line-height: 20px; 
    border-radius: 4px; 
    resize: none; 
    flex-grow: 3; 
    padding: 8px;
    }
`;

const ButtonDiv = styled.div`
  position: relative;
  width: 100%;
`;