import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';

export default function ComponentOptionSelector(props){

    const [selectedOption, setSelectedOption] = useState(-1);

    // handles which one is the option selected
    const handleSelectedOption = (option) => {
        if(option === selectedOption){
            setSelectedOption(-1)
            props.setEnable(false)
        }
        else{
            setSelectedOption(option)
            props.setEnable(true)
        }
      };

    return(
        <Container>
            {props.options.map((option, index)=>{
                return(
                    <Option id={"option_"+ option} BackgroundColor={index == selectedOption ? "#E2336B" : "#F1F3F4"} Color={index == selectedOption ? "#FCFCFC" : "#271B36"} 
                            Outline="solid 2px #E2336B" type="submit" value={option} onClick={() => {props.handleAnswer(option); handleSelectedOption(index)}}>
                        {option}
                    </Option>
                ) 
            })}
        </Container>
    )
}


const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 80px;
  gap: 16px;
`;

const Option = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%; 
  text-align: center;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-size: 18px; 
  cursor: ${props => props.Cursor || "pointer"};; 
  background-color: ${props => props.BackgroundColor || "#F1F3F4"};
  color: ${props => props.Color || "#271B36"};
  :hover{
      outline: ${props => props.Outline || ""};
  }

`;