import React, {useState, useEffect} from 'react';
import axios from 'axios';
import styled from "styled-components";
import logo from '../assets/login/Logo.svg';
import IconEmail from '../assets/login/IconMail.svg';
import lock_icon from "../assets/lock_open.png";
import lock_icon_shadow from "../assets/lock_open_shadow.png";
import {StyledInput, StyledDivRegular, StyledDivBold} from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import {Checkbox} from '@mui/material';
import getCookieValue from "../utils";
import {CenterContainerVarLeftMargin, HeightFloatToString} from '../layout-and-styling/standardized-components-library/Component-Responsive';
import ComponentLanguageSwitcher from "../layout-and-styling/standardized-components-library/Component-language-switcher";
import {useTranslation} from 'react-i18next';
import loadlogo from "../assets/dashboard/load.gif";


/** Login page and auth API */
const loginURL = process.env.REACT_APP_APIURL + '/api/auth/login/'

/** define axiosInstance for API requests */
const axiosInstance = axios.create({withCredentials: true})

// This file contains the login page where the user has to enter his/her credentials and accept the terms of service and privacy policy
const Login = () => {
    const [checked, setChecked] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(false);
    const [loginClicked, setLoginClicked] = useState(false);

    const {t} = useTranslation();

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    /** Built-in check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        /** Set document zoom at 1.0, just to be sure */
        document.body.style.zoom = 1.0

        /** create async function in order to use await promise sequence */
        const LoginCheck = async () => {
            try {
                const promise1 = await new Promise((resolve, reject) => {
                    axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
                        .then((res) => {
                            resolve('resolved promise1')
                        })
                        .catch((error) => {
                            console.log('error, you are not logged in yet!')
                        })
                })
                if (promise1) {
                    const promise2 = await new Promise((resolve, reject) => {
                        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/mydetails/')
                            .then(response => {
                                localStorage.setItem('current_user_data', JSON.stringify(response.data)) //store Employee API result
                            })
                            .then((res) => {
                                resolve('promise2')
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    });
                    if (promise2) {
                        const promise3 = await new Promise((resolve, reject) => {
                            axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myterms/')
                                .then(response => {
                                    let currentUser = JSON.parse(localStorage.getItem('current_user_data'));
                                    currentUser[0].is_developer = response.data.is_developer;
                                    currentUser[0].id = localStorage.setItem('current_user_data', JSON.stringify(currentUser))

                                })
                                .then((res) => {
                                    resolve('promise2')
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        });
                        if (promise3) {
                            let currentUser = JSON.parse(localStorage.getItem('current_user_data'));

                            if (currentUser[0].is_developer) {
                                const promise4 = await new Promise((resolve, reject) => {
                                    axiosInstance.get(process.env.REACT_APP_APIURL + '/api/companies/')
                                        .then(response => {
                                            localStorage.setItem('companies_data', JSON.stringify(response.data))
                                            localStorage.setItem('demo_mode', JSON.stringify(true))
                                            resolve('promise1')
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                });
                                if (promise4) {
                                    window.location.replace(process.env.REACT_APP_APPURL + '/home')
                                }
                            } else
                                window.location.replace(process.env.REACT_APP_APPURL + '/home')
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }
        /** call async function inside UseEffect */
        LoginCheck()
    }, []);

    /** Function to perform: 1) login authentication sequence, 2) necessary api calls and 3) re-direct to next page  */
    async function LoginNestedPromises() {
        /** Load in login form input */
        var bodyFormData = new FormData();
        bodyFormData.append("username", username)
        bodyFormData.append("password", password)
        bodyFormData.append("test", "test")

        /** Start nested Promise async sequence */
        try {

            /** Get CSRF and Session token via loginURL */
            const promise1 = await new Promise((resolve, reject) => {
                axiosInstance.get(loginURL)
                    .then((res) => {
                        resolve('promise1')
                    })
                    .catch(error => {
                        setLoginClicked(false)
                        console.log(error)
                    })
            });

            /** If CSRF and Session token request is resolved,
             * use form input and tokens to login via loginURL */
            if (promise1) {
                const promise2 = await new Promise((resolve, reject) => {
                    axiosInstance.post(loginURL, bodyFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        }
                    })
                        .then((res) => {
                            resolve('promise2')
                        })
                        .catch(error => {
                            setLoginClicked(false)
                            setErrors(true)
                            console.log(error)
                        })
                });

                /** If login request is resolved,
                 * GET request to get CurrentUserData.json via /api/mydetails/ */
                if (promise2) {
                    const promise3 = await new Promise((resolve, reject) => {
                        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/mydetails/')
                            .then(response => {
                                // TODO: Filter by survey and if survey isn't same as current wipe storage.
                                localStorage.clear()
                                sessionStorage.clear()
                                localStorage.setItem('current_user_data', JSON.stringify(response.data)) //store Employee API result
                            })
                            .then((res) => {
                                resolve('promise3')
                            })
                            .catch(error => {
                                setLoginClicked(false)
                                setErrors(true)
                                console.log(error)
                            })
                    });

                    /** If CurrentUserData.json request is resolved, re-direct to next page */
                    if (promise3) {
                        /** Perform TermsCheck PUT request */
                        const TermsCheck = {accepted_tos: checked}

                        const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
                        const promise4 = await new Promise((resolve, reject) => {
                            axiosInstance.put(process.env.REACT_APP_APIURL + '/api/myterms/' + CurrentUserData[0]['user'] + '/', TermsCheck, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "X-CSRFToken": getCookieValue("csrftoken"),
                                }
                            })
                                .then(response => {
                                    resolve('resolved promise4')
                                    let currentUser = JSON.parse(localStorage.getItem('current_user_data'));
                                    currentUser[0].is_developer = response.data.is_developer;
                                    localStorage.setItem('current_user_data', JSON.stringify(currentUser))
                                })
                                .catch(error => {
                                    setLoginClicked(false)
                                    console.log(error)
                                });
                        });
                        if (promise4) {
                            /** Get Companies via API /api/companies/ */
                            let currentUser = JSON.parse(localStorage.getItem('current_user_data'));
                            const promise5 = await new Promise((resolve, reject) => {
                                axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/')
                                    .then(response => {
                                        if (JSON.stringify(response.data) != '[]'){
                                            localStorage.setItem('intakeData', JSON.stringify(response.data))
                                            resolve('promise5')
                                        }
                                        else{
                                            resolve('promise5')
                                        }
                                    }
                                    )
                                }
                            )
                            if (promise5){
                                const promise6 = await new Promise((resolve, reject) => {
                                    axiosInstance
                                        .get(
                                            process.env.REACT_APP_APIURL +
                                            "/api/sections_available/"
                                        )
                                        .then((res) => {
                                            localStorage.setItem("allow_survey", res.data["survey"]);
                                            localStorage.setItem("allow_chatbot", res.data["chatbot"]);
                                            localStorage.setItem("allow_dashboard", res.data["dashboard"]);
                                            resolve('promise6')
                                        })
                                        .catch((error) => {
                                            console.log("ERROR: Could not fetch /api/dashboard_availability/");
                                        });
                                    })
                            
                                if (promise6) {
                                    if (currentUser[0].is_developer) {
                                        const promise7 = await new Promise((resolve, reject) => {
                                            axiosInstance.get(process.env.REACT_APP_APIURL + '/api/companies/')
                                                .then(response => {
                                                    localStorage.setItem('companies_data', JSON.stringify(response.data))
                                                    localStorage.setItem('demo_mode', JSON.stringify(true))
                                                    resolve('promise1')
                                                })
                                                .catch(error => {
                                                    setLoginClicked(false)
                                                    console.log(error)
                                                })
                                        });
                                        if (promise7) {
                                            window.location.replace(process.env.REACT_APP_APPURL + '/home')
                                        }
                                    } else
                                    if(checked)
                                    {
                                        window.location.replace(process.env.REACT_APP_APPURL + '/home')
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            setLoginClicked(false)
            console.log(err)
        }
    }

    /** Initial Log-in and if authentication OK, set auth token and re-direct to the below directory and run API get calls for Survey*/
    const onSubmit = e => {
        e.preventDefault();
        
        LoginNestedPromises();
    }


    return (
        <Container>
            <LeftContainer>

                <LogoWrapper> <img src={logo}/> </LogoWrapper>

                <StyledDivRegular MarginTop={HeightFloatToString(160 / 900)}>

                    <Email_icon_wrapper MarginTop="0px">
                        <img src={IconEmail} alt=""/>
                    </Email_icon_wrapper>

                    <StyledDivBold MarginTop='122px' MarginLeft='222px' Color='#FCFCFC' FontSize='42px'
                                   LineHeight='52px'>
                        {t('Login.TextHeader1')}
                    </StyledDivBold>

                    <StyledDivRegular MarginTop='214px' MarginLeft='222px' Color='#FCFCFC' Height='120px' Width='600px'
                                      FontSize='18px' LineHeight='28px'>
                        {t('Login.TextWrapper1')}
                        <br/>
                        <br/>
                        {t('Login.TextWrapper2')}
                        <br/>
                        <br/>
                        {t('Login.TextWrapper3')}
                    </StyledDivRegular>

                </StyledDivRegular>
            </LeftContainer>

            <RightContainer>


                {/* <AnimateKeyframes IntroFrameBackground
                        play={true}
                        duration={0.65}
                        delay={1}
                        iterationCount={6}
                        direction="alternate"
                        keyframes={[
                          { 0: 'opacity: 0' }, // 0%
                          { 50: 'opacity: 0.5' }, // 50%
                          { 100: 'opacity: 1' } // 100%

                        ]}                
                    >
                      <ComponentLanguageSwitcher reRender={reRender} MarginTop={"32px"}/>  
                </AnimateKeyframes> */}
                <ComponentLanguageSwitcher Top="10px"/>
                <StyledDivRegular MarginTop={HeightFloatToString(160 / 900)}>
                    
                    <LockIconWrapper MarginTop='0px'>
                        <img src={lock_icon} height="60%" width="60%" alt=""/>
                    </LockIconWrapper>

                    <LockIconWrapper2 MarginTop='8px'>
                        <img src={lock_icon_shadow} height="60%" width="60%" alt=""/>
                    </LockIconWrapper2>

                    <Heading MarginTop='122px'>
                        {t('Login.TextHeader2')}
                    </Heading>

                    <FormContainer MarginTop='214px'>
                        <form onSubmit={onSubmit}>
                            <StyledDivRegular MarginTop='' MarginLeft='' Width='300px' Height='20px' FontSize='18px'
                                              LineHeight='28px'>
                                {t('Login.Email')}
                            </StyledDivRegular>

                            <StyledInput style={{marginTop: '40px', marginLeft: '0px'}} MarginLeft='' Height='40px'
                                         Width='380px' ActiveBackground='#FCFCFC'
                                         FontSize='18px' LineHeight='28px' PlaceholderFontSize='18px'
                                         PlaceholderLineHeight='28px'
                                         name="email"
                                         type="email"
                                         placeholder="name@example.com"
                                         value={username}
                                         onChange={e => setUsername(e.target.value)}
                            />
                            <StyledDivRegular MarginTop='100px' MarginLeft='' FontSize='18px' LineHeight='28px'
                                              Width="50px">
                                {t('Login.Password')}
                            </StyledDivRegular>

                            <StyledInput style={{marginTop: '136px', marginLeft: '0px'}} Height='40px' Width='380px'
                                         ActiveBackground='#FCFCFC'
                                         FontSize='18px' LineHeight='28px' PlaceholderFontSize='18px'
                                         PlaceholderLineHeight='28px'
                                         name="password"
                                         type="password"
                                         placeholder={t('Login.Password')}
                                         value={password}
                                         onChange={e => setPassword(e.target.value)}
                            />

                            <StyledDivRegular MarginTop='190px' MarginLeft='0px' Color='#F72B2B' Width='400px'
                                              Height='20px'
                                              Display={errors === true ? '' : 'none'}>
                                {t('Login.ErrorText')}
                            </StyledDivRegular>

                            <CheckboxWrapper id="termsbtn" MarginTop='220px' MarginLeft=''>
                                <Checkbox size="large" sx={{'& .MuiSvgIcon-root': {fontSize: 28},}}
                                          style={{color: "#e2336B", margin: "-12px",}}
                                          value={checked} onChange={handleCheckboxChange}/>
                            </CheckboxWrapper>

                            <Terms MarginTop='220px' MarginLeft='30px' FontSize='18px' LineHeight='28px'>
                                {t('Login.TextWrapper4')}<a href="https://elephantsintheroom.co/terms-of-service/"
                                                            target="_blank" style={{textDecoration: "none"}}>
                                <span>{t('Login.TermsOfServiceText')}</span></a>
                                {t('Login.AndText')}<a href="https://elephantsintheroom.co/privacy-policy/"
                                                       target="_blank" style={{textDecoration: "none"}}>
                                <span> {t('Login.PrivacyPolicyText')}</span></a>
                            </Terms>

                            {!loginClicked ?
                                <ButtonWrapper MarginTop='276px' MarginLeft='' Height='56px' Width='160px'
                                            PointerEvents={checked ? "all" : "none"}
                                           BackgroundColor={checked ? "#e2336B" : "#F7C9D9"}
                                           Cursor={checked ? "pointer" : "default"}>
                                <button onClick={() => {
                                    setLoginClicked(true)
                                    LoginNestedPromises();
                                    }} type='submit' value='Login' disabled={!checked}>
                                    {t('Login.ButtonText')}
                                </button>
                            </ButtonWrapper>
                            :
                            <ButtonWrapper MarginTop='276px' MarginLeft='' Height='56px' Width='160px'
                                            PointerEvents={checked ? "all" : "none"}
                                            BackgroundColor={checked ? "#e2336B" : "#F7C9D9"}
                                            Cursor={checked ? "pointer" : "default"}>
                                <LoadLogo>
                                    <button disabled={!checked}>
                                        {t('Login.ButtonText')}
                                    </button>
                                    <img src={loadlogo} alt="loading..."/>
                                    
                                </LoadLogo>
                            </ButtonWrapper>
                            }
                        </form>
                    </FormContainer>

                </StyledDivRegular>

            </RightContainer>

            <RightBg Width={CenterContainerVarLeftMargin()}/>
        </Container>

    );
};


export default Login;

const Container = styled.div`
  background: #FCFCFC;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }

`;
const LeftBg = styled.div`
  width: ${props => props.Width || "760px"};
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: start;
  background-color: #271B36;
  @media (max-width: 1920px) {
    display: none;
  }
`;
const RightBg = styled.div`
  width: ${props => props.Width || "760px"};
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: start;
  background-color: #FCFCFC;
  @media (max-width: 1920px) {
    display: none;
  }
`;


const LeftContainer = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: start;
  background-color: #271B36;
  flex-direction: column;

  h1 {
    margin-top: 368px;
    margin-left: 220px;
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    line-height: 52px;
    font-weight: 700;
    color: #FCFCFC;
    position: absolute;

  }

  span {
    width: 580px;
    font-family: 'Overpass', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #FCFCFC;
    position: absolute;
    margin-top: 460px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  z-index: 1;
  margin-top: ${props => props.MarginTop || "40px"};
  margin-left: ${props => props.MarginLeft || "70px"};
`;

const Email_icon_wrapper = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "160px"};
  margin-left: ${props => props.MarginLeft || "220px"};
  z-index: 2;
`;


const RightContainer = styled.div`
  width: 45%;
  background-color: #FCFCFC;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
`;

export const LanguageSwitcher = styled.div`
  margin-left: 75%;
  margin-top: 15px;
  position: relative;
  height: 50px;
`;

const LockIconWrapper = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "160px"};
  margin-left: ${props => props.MarginLeft || "150px"};
  z-index: 2;
`;

const LockIconWrapper2 = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "168px"};
  margin-left: ${props => props.MarginLeft || "158px"};

  z-index: 1;
`;

const Heading = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "282px"};
  margin-left: ${props => props.MarginLeft || "160px"};
  font-family: 'Montserrat', sans-serif;
  font-size: 42px;
  font-weight: 700;
  color: #271B36;
  line-height: 52px;
`;

const FormContainer = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "374px"};
  margin-left: ${props => props.MarginLeft || "160px"};
  width: 400px;
  height: 312px;
`;

const Terms = styled.p`
  font-family: 'Overpass';
  font-size: 18px;
  color: #271B36;
  font-weight: 400;
  position: fixed;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  text-align: left;

  span {
    font-family: 'Overpass';
    font-size: 18px;
    color: #e3316b;
    font-weight: 400;
  }
`;

const CheckboxWrapper = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  z-index: 3;

  button {
    width: ${props => props.Width || "160px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "8px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    font-size: ${props => props.FontSize || "18px"};
    line-height: ${props => props.LineHeight || "28px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    cursor: ${props => props.Cursor || "pointer"};
    transition: all 0.2s ease-in;
    align-items: start;

    pointer-events: ${props => props.PointerEvents || "none"};

    :hover {
        background-color: #B3194A;
    }
  }
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
    margin-left: 55px;
    margin-top: 3px;
  }
  button {
    opacity: 0.2;
    position: absolute;
    cursor: unset;
  }
  display: flex;
  justify-content: flex-start;
  position: relative;
`;
