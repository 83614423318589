import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as SurveyFooterBackButton} from "../../assets/footer/BackButton_Survey_56px.svg";
import { handleLogout } from '../API-calls/common-api-calls';
import {StyledDivRegular} from './Styling-TextInput';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import logo from "../../assets/dashboard/load.gif";
import getCookieValue from '../../utils';


function CommonFooter(props) {
    /** Component to build footer buttons, with the corresponding API calls.
     * First section you find all the API get, post and update request functions
     * Second section you find the if statements for specific pages, to load in specific styling and API requests
     * Third section you find the styled div css styling
     */

    /** Go from one directory to another via useHistory */
    let history = useHistory();
    const {t} = useTranslation();
    const [advance, setAdvance] = useState(false);
    const axiosInstance = axios.create({withCredentials: true});
    
    /** Workaround for API and rendering issues, with timeouts and forced re-renders
     * TODO: async await API handshakes
     * TODO: proper first render of mapped localStorage data, without the need of page refresh
     */
    /** If else statement, to only push ArrayCloseColleagues for now at "PageCommFreqMeasFilter */
    if (props.page === "LeaderIntakeFirstPage") {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperNext MarginTop='860px' MarginLeft='1540px' Height='48px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >Next
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "LeaderIntake") {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='860px' MarginLeft='1464px' Height='48px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='860px' MarginLeft='1540px' Height='48px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >Next
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageIntakeRepresentative") {
        return (
            <IntakeContainer>
                <IntakeWrapper>
                    <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft} Width={props.BackWidth} Height={props.BackHeight}>
                        <button>
                            <SurveyFooterBackButton onClick={() => {
                                history.push(props.prevdirectory)
                            }}/>
                        </button>
                    </ButtonWrapperBack>

                    {!advance ? (
                        <ButtonWrapperNext isDisabled={props.isDisabled} MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents='all' Width={props.Width}
                                           Height={props.Height}>
                            <button onClick={() => {
                                props.setNextButtonClicked(true);
                                
                                if (!props.isDisabled) {
                                    setAdvance(true);
                                    props.handleClick();
                                }
                            }}>
                                <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700'
                                                  LetterSpacing='0.3px'>
                                    {t('PageIntakeRepresentative.TextButton')}
                                </StyledDivRegular>
                            </button>
                        </ButtonWrapperNext> 
                    ) : ( 
                        <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} Width={props.Width} Height={props.Height}>
                            <ButtonWrapperLogo>
                                <button disabled={true}/>
                                <img src={logo} alt="loading..."/>   
                            </ButtonWrapperLogo>
                        </ButtonWrapperNext>
                    )}
                </IntakeWrapper>
            </IntakeContainer>
        );
    }

    if (props.page === "PageIntakeProcessTime") {
        return (
            <IntakeContainer>
                <IntakeWrapper>
                    <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft} Width={props.BackWidth} Height={props.BackHeight}>
                        <button>
                            <SurveyFooterBackButton onClick={() => {
                                history.push(props.prevdirectory)
                            }}/>
                        </button>
                    </ButtonWrapperBack>

                    {!props.allowAdvance ? (
                        <ButtonWrapperNext isDisabled={props.isDisabled} MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents='all' Width={props.Width}
                                           Height={props.Height}>
                            <button onClick={() => {
                                props.setNextButtonClicked(true);

                                if (!props.isDisabled) {
                                    props.handleClick();
                                }
                            }}>
                                <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700'
                                                  LetterSpacing='0.3px'>
                                    {t('PageIntakeRepresentative.TextButton')}
                                </StyledDivRegular>
                            </button>
                        </ButtonWrapperNext> 
                    ) : ( 
                        <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} Width={props.Width} Height={props.Height}>
                            <ButtonWrapperLogo>
                                <button disabled={true}/>
                                <img src={logo} alt="loading..."/>   
                            </ButtonWrapperLogo>
                        </ButtonWrapperNext>
                    )}
                </IntakeWrapper>
            </IntakeContainer>
        );
    }

    if (props.page === "SurveyCompleted") {
        return (
            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"} Width={props.Width} Height={props.Height} BorderRadius={props.BorderRadius}>

                    <button
                        onClick={() => {
                            axiosInstance
                                .post(
                                    process.env.REACT_APP_APIURL +
                                    "/api/pagetimestamp/",
                                    {"page": "survey-complete"},
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                            "X-CSRFToken": getCookieValue("csrftoken"),
                                        },
                                    }
                                )
                                .then (() => {
                                    handleLogout()
                                })
                        }}
                    >
                        {/*Logout*/}
                        {t('PageSurveyCompleted.ButtonText')}
                    </button>
                </ButtonWrapperNext>
            </>
        );
    }

    if (props.page === "Logout") {
        return (
            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                   Width={props.Width} Height={props.Height} BorderRadius={props.BorderRadius}>

                    <button
                        onClick={() => {
                            handleLogout()
                        }} >
                        {t('Logout.LogoutButton')}
                    </button>
                </ButtonWrapperNext>
            </>
        );
    }
    
    else {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='853px' MarginLeft='1464px' Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='853px' MarginLeft='1540px' Height='56px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >{t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    };
};
export default CommonFooter

const Wrapper = styled.div`
  position: fixed;
  margin-left: ${props => props.MarginLeft || ""};
  height: ${props => props.Height || "104px"};
  width: ${props => props.Width || "100%"};
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};
  border-top: 1px solid #E3E5E4;
  z-index: 1;
  display: ${props => props.Display || ""};
  bottom: 0; //fixes the footer to the bottom of the page

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const IntakeContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: calc(100% - 80px);
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-top: 1px solid #E3E5E4;
  background: #FCFCFC;

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const IntakeWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1290px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const ButtonWrapperBack = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-bottom: ${props => props.MarginBottom || ""};
  margin-top: ${props => props.MarginTop || "39.4%"};
  margin-left: ${props => props.MarginLeft || "76.5%"};

  @media only screen and (max-width: 1700px) {
    margin-left: ${props => props.MarginLeft || "75.5%"};
  }

  z-index: 4;
  width: ${props => props.Width || "39.4%"};
  height: ${props => props.Height || "39.4%"};

  button {
    border: none;
    padding: 0;
    background: none;
    cursor: ${props => props.Cursor || "pointer"};
  }
`;

const ButtonWrapperNext = styled.div`
  justify-content: center;
  margin-top: ${props => props.MarginTop || "22px"};
  margin-left: ${props => props.MarginLeft || "80.1%"};
  z-index: 4;

  button {
    width: ${props => props.Width || "136px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "8px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    font-size: ${props => props.FontSize || "18px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    opacity: ${props => props.isDisabled ? '0.5' : props.Opacity || '1'};
    cursor: ${props => props.isDisabled ? '' : props.Cursor || 'pointer'};
    transition: all 0.2s ease-in;
    align-items: flex-start;

    pointer-events: ${props => props.PointerEvents || "none"};

    :hover {
        background-color: ${props => props.isDisabled ? '' : '#B3194A'};
    }
  }
`;

const ButtonWrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    width: 40px;
  }

  button {
    cursor: unset;
    background: #E3E5E4;
  }
`;