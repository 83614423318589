import React, {useContext, useState, useEffect} from 'react';
import styled from '@emotion/styled';
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import botImage from '../assets/botImage.png';
import ChatbotLoading from "../assets/ChatbotLoading.gif"


export default function ComponentMessage(props){
    let alignment = "right";
    let color = "#D0E1F1";
    if(props.owner){
        alignment = "left";
        color = "#F7C9D9";
    }

    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const context = useContext(OverlayContext);

    function TypeWriterEffect( text, typingSpeed = 10 ) {
        const [displayedText, setDisplayedText] = useState('');
      
        useEffect(() => {
          if (text.length > displayedText.length) {
            props.setBotWriting(true)
            const timeoutId = setTimeout(() => {
              setDisplayedText(text.substring(0, displayedText.length + 1));
            }, typingSpeed);
            return () => clearTimeout(timeoutId);
          } else {
            props.setBotWriting(false)
          }
        }, [text, displayedText, typingSpeed]);

        // scrolling down the messages
        props.scrollDown()

        return displayedText;
    }

    return(
        <Container>
            {/* profile picture for Elly*/}
            {(props.owner) && 
                <ProfilePicture>
                    <img src={botImage} style={{width: '48px', height: '48px'}} ></img>
                </ProfilePicture>
            }
            <MessageContainer>
                {/* Triangle for Elly*/}
                {props.owner ? (
                    <>
                        <Triangle Left="6px">
                            <svg width="14" height="12">
                                <polygon points="14,0 0,0 14,12" style={{ fill: color }} />
                            </svg>
                        </Triangle>
                        <Message style={{backgroundColor: color}}>
                        {props.thinking ? (
                            <img src={ChatbotLoading} style={{width: '20px', height: '20px'}}></img>
                        ) : (
                            <>{TypeWriterEffect(props.message)}</>
                        )}
                        </Message>
                    </>
                ) : (
                    <>
                    <Message style={{backgroundColor: color}}>
                        {props.message}
                    </Message>
                    <Triangle Left="-6px">
                        <svg width="14" height="12">
                            <polygon points="0,0 0,12 14,0" style={{ fill: color }} />
                        </svg>
                    </Triangle>
                    </>
                )}

                

            </MessageContainer>
            {/* profile picture for the user*/}
            {(!props.owner) &&
                <ProfilePicture>
                    {context.setFirstName(currentUser.first_name, 0).charAt(0)
                    + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                </ProfilePicture>
            }
        </Container>
    )
}

const Container = styled.div`
display: flex;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 24px;
    width: fit-content;
    max-width: 700px;
`;

const Message = styled.div`
    border-radius: 8px;
    padding: 12px;
    font-family: 'Overpass';
    font-size: 18px;
    line-height: 22px;
`;

const ProfilePicture = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #D0E1F1;
  border-radius: 100px;
  justify-content: center;
  color: #000000;
  font-family: 'Overpass';
  font-size: 20px;
  margin: 0px;
`;

const Triangle = styled.div`
    position: relative;
    display: flex;
    left: ${props => props.Left || "0px"};
    height: 12px;
    width: 14px;
    `;