import React, {useState, useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import ComponentMessage from './Component-Message';

export default function ComponentChatbox(props){  
  
  // reference to the text area
  const conversationRef = useRef();


  // scrolls the conversation box down when a new message is added
  useEffect(() => {
    scrollDown()
  }, [props.botThinking]); 

  // scrolls the conversation box down when a new message is added
  const scrollDown = () => {
    if(conversationRef.current)
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
  }

  return(
      <Container ref={conversationRef}>
        <MessagesWrapper>
          {props.messages.map((item, index)=>{
              if(item["owner_is_bot"]){
                  return <div style={{justifyContent: 'left', display: 'flex'}}> <ComponentMessage message={item["message"]} owner={item["owner_is_bot"]} setBotWriting={props.setBotWriting} scrollDown={scrollDown}/> </div>
              }
              return <div style={{justifyContent: 'right', display: 'flex'}}> <ComponentMessage message={item["message"]} owner={item["owner_is_bot"]} setBotWriting={props.setBotWriting} scrollDown={scrollDown}/> </div>
          })}
          {props.botThinking && <ComponentMessage message="loading" thinking={props.botThinking} owner={true} setBotWriting={props.setBotWriting} scrollDown={scrollDown}/>}
        </MessagesWrapper>
      </Container>
  )
}

const Container = styled.div`
  display: flex;
  border-style: solid;
  flex-direction: column;
  width: calc(100% - 24px);
  height: calc(100% - 238px);
  background-color: #FCFCFC;
  border-color: #C6CBCB;
  border-radius: 20px;
  border-width: 1px;
  font-size: 14px;
  font-weight: 400;
  white-space: normal;
  overflow-y: scroll;
  padding: 24px 8px 12px 16px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;


const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
`;

