import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ProgressBar from "@ramonak/react-progress-bar";

const ProgressBarComponent = (props) => {


    const steps = [
        {
            value: "0%",
            label: "Start"
        },
        // {
        //     value: "25%",
        //     label: "Problem"
        // },
        // {
        //     value: "50%",
        //     label: "Root cause"
        // },
        // {
        //     value: "75%",
        //     label: "Solutions"
        // },
        {
            value: "90%",
            label: "Finish"
        },
    ];


    return (
        <ProgressBarWrapper>
            <Bar style={{ width: '100%'}}>
              <ProgressBar completed={props.progress} customLabel=" " height='8px' width='100%' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
            </Bar>
            <Labels>
                {steps.map((step, index) => (
                    <StyledDivRegular Position="relative" Width='fit-content' Height='fit-content'> {step["label"]} </StyledDivRegular>
                ))}
            </Labels>
        </ProgressBarWrapper>
    )
}

export default ProgressBarComponent;

const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 8px;
`;

const Bar = styled.div`
  width: 100%;
`;

const Labels = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  justify-content: space-between;
  gap: 64px;
`;
