import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import SurveyTextProcessingHeader from '../standardized-components/Survey-text-processing-header';
import styled from 'styled-components';
import ConversationWrapper from './page-specific-components/Component-Chatbox';
import SendMessageComponent from './page-specific-components/Component-Sendmessage';
import ProgressBarComponent from './page-specific-components/Component-ProgressBar';
import getCookieValue from "../../utils";
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from 'react-i18next';
import { TooltipRootcauseChatbot } from '../standardized-components/Survey-text-processing-tooltips';
import { OverlayContext } from '../../layout-and-styling/context-hooks/OverlayContext';
import { GetResponse } from '../standardized-components/api-calls/survey-text-processing-api-calls';


const PageRootcauseChatbot = () => {

  const context = useContext(OverlayContext);
  const {t, i18n} = useTranslation();

  // the intake now has 3 stages (intro, intake and done)
  const [intake, setIntake] = useState(JSON.parse(localStorage.getItem('chatbot_intake')) ? JSON.parse(localStorage.getItem('chatbot_intake')) : "done");
  // current stage of the chatbot, we first check if there are messages stored in the local storage, if that is the case, the stage is the one from the last message, otherwise "INTRODUCTION"
  const [stage, setStage] = useState(JSON.parse(localStorage.getItem('chatbot_original_messages')) ? JSON.parse(localStorage.getItem('chatbot_original_messages'))[JSON.parse(localStorage.getItem('chatbot_original_messages')).length-1]["stage"] : "INTRODUCTION");
  // if it is elly's turn to write but she is not doing it yet, she is waiting for the backend to send the next question
  const [botThinking, setBotThinking] = useState(false);
  // is elly is writing, writing takes some time because the messages is written like work by word
  const [botWriting, setBotWriting] = useState(false);
  // options in case the answer is composed of options, we need to store also in the local storage in case of page refresh
  const [options, setOptions] = useState(JSON.parse(localStorage.getItem('chatbot_options')) ? JSON.parse(localStorage.getItem('chatbot_options')) : []);
  // tooltip hover
  const [hover1, setHover1] = useState(false);
  // progress of the conversation
  const [progress, setProgress] = useState(JSON.parse(localStorage.getItem('chatbot_progress')) ? JSON.parse(localStorage.getItem('chatbot_progress')) : 0);


  const [translate, setTranslate] = useState(false);

  // Array of dictionaries (a dictionary for each message) {message, owner_is_bot, stage}
  // the messages in this array are always in english, are the ones sended to the backend
  const [englishMessages, setEnglishMessages] = useState(JSON.parse(localStorage.getItem('chatbot_english_messages')) ? JSON.parse(localStorage.getItem('chatbot_english_messages')) : []);
  
  // Array of dictionaries (a dictionary for each message) {message, owner_is_bot, stage}
  // the messages in this array are in the language selected by the user and that he/she answers
  const [originalMessages, setOriginalMessages] = useState(JSON.parse(localStorage.getItem('chatbot_original_messages')) ? JSON.parse(localStorage.getItem('chatbot_original_messages')) : []);

  useWindowSize();

  // start of the conversation, if there are less than two messages in the conversation
  // we execute the next message, then we wait until botWriting changes its value, that will be when the first message is completely written
  // then next_message is executed again to get the second message
  useEffect(() => {
    if(intake=="done" && !botWriting){
      if (englishMessages.length < 1) {
        nextMessage("")
      }
    }
  }, [botWriting, intake]);
    
  /**
     * Adds a message to the messages state, containing all the messages of the conversation. Also updates the stage 
     * @param {String} original_message - message in the conversation language (either from elly of from the user)
     * @param {String} english_message - message translated to english (either from elly of from the user)
     * @param {Boolean} owner_is_chatbot - true if message sent by elly, false if sent by user
     * @param {String} stage - stage of the conversation we are
     * @param {Array} options - options that the user will have to select in the answer ([] if the owner_is_chatbot is false)
     * the states and the local storage are updated
     */
  const addMessage = (original_message, english_message, owner_is_chatbot, stage, options) => {
    let new_messages = []

    // english messages
    // setting messages state + local storage
    new_messages = englishMessages
    new_messages.push({
      "message" : english_message,
      "owner_is_bot" : owner_is_chatbot,
      "stage" : stage
    })

    setEnglishMessages(new_messages);
    localStorage.setItem('chatbot_english_messages', JSON.stringify(new_messages));

    // original messages
    // setting messages state + local storage
    new_messages = originalMessages
    new_messages.push({
      "message" : original_message,
      "owner_is_bot" : owner_is_chatbot,
      "stage" : stage
    })

    setOriginalMessages(new_messages);
    localStorage.setItem('chatbot_original_messages', JSON.stringify(new_messages));
    
    // setting stage state
    setStage(stage)

    // setting the default options
    setOptions(options)
    localStorage.setItem('chatbot_options', JSON.stringify(options));
  }

  /**
     * Processes the messages sent by the user and calls the API to get the response
     * @param {String} message - Message sent by the user
     */
  async function nextMessage(message) {
    // user message
    // the condition of the if will only happen in the first messages where the chatbot sends messages without the user having to say anything 
    let english_message = message
    if(message != ""){
      // if language is not english we translate
      if(i18n.language != "en-US"  && translate){
        english_message = await translateWithDeepL(message, i18n.language.split('-')[1]);
      }

      // adding the message from the user to the state + local storage, options ([]) are not important here
      addMessage(message, english_message, false, stage, [])
    }

    // chatbot is processing the answer
    setBotThinking(true)

    // calling the response from the backend, we only send the message in english, the rest of info is stored in the backend itself or calculated there if needed
    let response = await GetResponse(english_message);
    let next_stage = response["stage"]
    let next_message = response["message"]
    let next_options = response["options"]
    setProgress(response["progress"])
    localStorage.setItem('chatbot_progress', JSON.stringify(response["progress"]));

    // chatbot message
    let original_message = next_message
    // if language is not english we translate
    if(i18n.language != "en-US" && translate){
      // english messages translated to original language
      original_message = await translateWithDeepL(next_message, i18n.language.split('-')[1]);
      // translation of the options (if we are in the process or subprocess selection we don't translate)
        for (let i = 0; i < next_options.length; i++) {
          // treating exception where deepL translates No to Geen
          if(i18n.language == "nl-NL" && next_options[i] == "No") 
            next_options[i] = "Nee"
          else
            next_options[i] = await translateWithDeepL(next_options[i], i18n.language.split('-')[1]);
        }
    }


    // adding the message from elly to the state + local storage
    addMessage(original_message, next_message, true, next_stage, next_options)

    // chatbot is processing the answer
    setBotThinking(false)
    
  }

  /**
     * Translates the message using DeepL api 
     * @param {String} text - Message to translate
     * @param {String} targetLanguage - Language that the message has to be translated to
     */
  async function translateWithDeepL(text, targetLanguage){
    const axiosInstance = axios.create({withCredentials: true});
    const baseURL = process.env.REACT_APP_APIURL;
    const data = `{ 
      "text": "${text}", 
      "targetLanguage": "${targetLanguage}"
    }`
    return axiosInstance.post(baseURL+"/api/translate/", data, {
      headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookieValue("csrftoken")
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return t("PageRootcauseChatbot.TranslationFail");
    })
  }

  return(
    <Container>
      <HeaderAndOverlayWrapper>
        <SurveyTextProcessingHeader page='EllyPage'/>
      </HeaderAndOverlayWrapper>

      <ChatbotWrapper>
        <TitleWrapper>
          <Trans i18nKey="PageRootcauseChatbot.HeaderText">
            Talk <span style={{color: "#B3194A"}}> Talk </span>
            to Elly to find the root cause of your problems
          </Trans>
          <InfoHover onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
            ?
            {hover1 && (<TooltipRootcauseChatbot tooltip="Chatbot"/> )}
          </InfoHover>
        </TitleWrapper>

        <ProgressBarComponent progress={progress}/>
        <ConversationWrapper messages={originalMessages} botThinking={botThinking} setBotWriting={setBotWriting}/>
        <SendMessageComponent nextMessage={nextMessage} botThinking={botThinking} botWriting={botWriting} stage={stage} options={options}/>
        
      </ChatbotWrapper>

    </Container>
  );
}

export default PageRootcauseChatbot

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;

const ChatbotWrapper = styled.div`
  position: relative;
  margin-top: 100px;
  padding: 32px 32px 24px 32px;
  width: 90%;
  max-width: 1920px;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FCFCFC;
  border-radius: 12px;
  gap: 24px;
`;

const TitleWrapper = styled.div`
  height: fit-content;
  font-family: montserrat;
  font-weight: bold;
  font-size: 32px;
  background: #FCFCFC;
  text-align: center;
  width: 100%;
`;

const InfoHover = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 18px;
  font-family: 'Overpass';
  font-weight: 700;
  color: #FCFCFC;
  background: #E2336B;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
`;