import styled from 'styled-components';
import React, { useContext, useState } from "react"
import { Dock } from "react-dock"
import { Trans, useTranslation } from 'react-i18next';
import { StyledDivBold, StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';

import { TooltipKnowledgePage, TooltipProcessBadFeedback } from "./Survey-text-processing-tooltips";
import ProcessTimePortionGif from '../common-assets/intro-gifs/ProcessTimePortion.gif';
import KnowledgeBusinessProcessGif from '../common-assets/intro-gifs/KnowledgeBusinessProcess.gif';
import ImpactCompanyGif from '../common-assets/intro-gifs/ImpactCompany.gif';
import BestPracticesGif from '../common-assets/intro-gifs/BestPractices.gif';
import TagsBestPracticesGif from '../common-assets/intro-gifs/TagsBestPractices.gif';
import WrittenGoodFeedbackGif from '../common-assets/intro-gifs/WrittenGoodFeedback.gif';
import botImage from '../chatbot/assets/botImage.png';


function SurveyTextProcessingOverlay(props) {
    /**
     * ComponentDockOver is the functional component and it's styling for creating overlays such as the Survey Introduction pages.
     * Dock is imported from react-dock and where you can look up it's arguments described there
     */

    /** Needed for back and next button browser url history */
    const { t, i18n } = useTranslation();

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);



    if (props.page === "PageKnowledge") {
        return (
            <Dock style={{ display: "none" }} position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('IntroPageKnowledge.Title')}
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageKnowledge.Subtitle')}

                        </StyledDivRegular>
                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox1">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<TooltipKnowledgePage
                                            whichTooltip={"Step1"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox2">
                                </Trans>

                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={ProcessTimePortionGif} />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                           
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox3">
                                </Trans>
                                <br></br>
                                <Trans i18nKey="IntroPageKnowledge.ExplanationBox4">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover2(true)} onMouseOut={() => setHover2(false)}>
                                        {hover2 && (<TooltipKnowledgePage
                                            whichTooltip={"Step2"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px">
                                <img height={300} src={ImpactCompanyGif} />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox5">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover3(true)} onMouseOut={() => setHover3(false)}>
                                        {hover3 && (<TooltipKnowledgePage
                                            whichTooltip={"Step3"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox6">
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px">
                                <img height={300} src={KnowledgeBusinessProcessGif} />
                            </IntroImageWrapper>

                        </StepThreeDiv>
                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>

                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        )
    }

    else if (props.page === "PageBusinessProcessGoodFeedback") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                                Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageGoodFeedback.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize={i18n.language === "nl-NL" ? '22px' : '26px'} FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageGoodFeedback.Subtitle')}

                            </StyledDivRegular>
                        </SurveyIntroHeadingBlock>


                        <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox1">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<TooltipProcessBadFeedback
                                            whichTooltip={"businessFlow"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox2">
                                </Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={BestPracticesGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='580px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox3"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={TagsBestPracticesGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                            <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox4"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={WrittenGoodFeedbackGif} />
                                </IntroImageWrapper>

                            </StepThreeDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>

            </>
        );
    }

    else if (props.page === "EllyPage") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper JustifyContent="center">

                        <SurveyIntroHeadingBg>

                            <SurveyIntroHeadingBlock Position="relative" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                                <StyledDivRegular Position='relative' Display="flex" Width="fit-content" Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                    {t('Component-Dock-Overlay-IntroductionText')}
                                </StyledDivRegular>

                                <StyledDivBold Position='relative' Display="flex" Width="fit-content" Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                    {t('IntroPageBadFeedback.Title')}
                                </StyledDivBold>

                                <StyledDivRegular Position='relative' Display="flex" MarginTop="-12px" Width={i18n.language === "en-US" ? "902px" : "nl-NL" ? "800px" : "600px"} Color='#FCFCFC' FontSize={i18n.language === "nl-NL" ? '22px' : '26px'} FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                    {t('IntroPageBadFeedback.Subtitle')}
                                </StyledDivRegular>
                            </SurveyIntroHeadingBlock>

                        </SurveyIntroHeadingBg> 


                        <EllyDiv MarginTop="64px">
                            <StyledDivBold Color='#E2336B' FontSize='28px' Position="relative" LineHeight='80px'>
                                Meet Elly
                            </StyledDivBold>
                            <img src={botImage} style={{width: '80px', height: '80px'}} ></img>
                        </EllyDiv>

                        <StepsWrapper MarginTop='64px' Width="95%">
                            <StepDiv>

                                <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px'>
                                    {t('IntroPageBadFeedback.ExplanationTitle1')}
                                </StyledDivBold>

                                <StyledDivRegular Position="relative" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='fit-content' Height='fit-content' MaxWidth='500px' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" >
                                    {t('IntroPageBadFeedback.ExplanationBox1')}
                                </StyledDivRegular>

                            </StepDiv>

                            <StepDiv>

                                <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px'>
                                    {t('IntroPageBadFeedback.ExplanationTitle2')}
                                </StyledDivBold>

                                <StyledDivRegular Position="relative" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='fit-content' Height='fit-content' MaxWidth='500px' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" >
                                    {t('IntroPageBadFeedback.ExplanationBox2')}
                                </StyledDivRegular>

                            </StepDiv>

                            <StepDiv>

                                <StyledDivBold Color='#E2336B' FontSize='24px' Position="relative" LineHeight='52px'>
                                    {t('IntroPageBadFeedback.ExplanationTitle3')}
                                </StyledDivBold>

                                <StyledDivRegular Position="relative" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='fit-content' Height='fit-content' MaxWidth='500px' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" >
                                    {t('IntroPageBadFeedback.ExplanationBox3')}
                                </StyledDivRegular>

                            </StepDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop="92px" ButtonWidth='271px' ButtonHeight='56px'>
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('IntroPageBadFeedback.StartConversation')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>

            </>
        );
    }

};
export default SurveyTextProcessingOverlay
const DockBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.JustifyContent || "flex-start"};
    z-index: 10;
    overflow-x: hidden;
    @media (-webkit-device-pixel-ratio: 1.25) {
        zoom: ${props => props.Zoom1 || ""};
    }
    @media (-webkit-device-pixel-ratio: 1.5) {
        zoom: ${props => props.Zoom2 || ""};
    }
`;


const SurveyIntroHeadingBg = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "0%"};
    box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
    background: #271B36;
    z-index: 10;
    padding: 64px;
`;

const SurveyIntroHeadingBlock = styled.div`
    position: ${props => props.Position || "fixed"};
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: ${props => props.Width || "900px"};
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "0%"};
    margin-right: ${props => props.MarginRight || "0%"};
    z-index: 10;
`;

const IntroImageWrapper = styled.div`
    position: ${props => props.Position || "fixed"};
    display: flex;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "0%"};
    justify-content: center;
    width: ${props => props.Width || ""};
`;


const HideDockButtonWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: ${props => props.MarginTop || "0px"};
    margin-left: ${props => props.MarginLeft || "0px"};

    button {
        height: ${props => props.ButtonHeight || "56px"};
        width: ${props => props.ButtonWidth || "212px"};
        border: none;
        border-radius: 10px;
        background-color: #e2336B;
        color: #FCFCFC;
        font-size: 18px;
        font-weight: 400;
        font-family: 'Overpass';
        cursor: pointer;
        transition: all 0.2s ease-in;
        :hover {
            background-color: #B3194A;
            }
}`;

const IntroOverlayNumber = styled.div`
    position: fixed;
    margin-top: -90px;
    margin-bottom: ${props => props.MarginBottom || "0%"};
    width: ${props => props.Width || "40px"};
    height: ${props => props.Height || "40px"};
    background: #e2336B;
    z-index: 3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${props => props.FontFamily || 'Overpass'};
    font-size: ${props => props.FontSize || '18px'};
    line-height: ${props => props.LineHeight || '28px'};
    font-weight: ${props => props.FontWeight || '400'};
    color: #fcfcfc;

    p {
        height: 25px;
    }
`;

const EllyDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
}`; 

const StepsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: ${props => props.Width || "70%"};
    height: 100%;
    justify-content: space-evenly;
    margin: 0 auto;
    margin-top: ${props => props.MarginTop || "70px"};
    @media only screen and (max-width: 2300px) {
  }
  `
  
const StepDiv = styled.div`
    position: relative;
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: ${props => props.Width || "33%"};
    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
}`; 


const StepOneDiv = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: ${props => props.Width || "33%"};
    margin-right: 40px;
    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
    margin-left: 0px;
  }`; 


const StepTwoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: ${props => props.Width || "33%"};
    margin-right: 40px;
    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
    margin-left: 0px;
  }`;

const StepThreeDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${props => props.MarginTop || "0"};
    margin-left: ${props => props.MarginLeft || "0"};
    width: 33%;
    margin-right: 40px;

    @media only screen and (min-width: 2000px) {
    margin-right: 0px;
    margin-left: 0px;
  } `;


const HoverTooltip = styled.div`
    display: inline;
    color: #E2336B;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    z-index: 2;
`;
